// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-control {
  font-family: Arial, sans-serif;
  background-color: rgba(231, 237, 244, 1);
  color: #505e71;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.access-control-container {
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/AccessControls/AccessControls/AccessControls.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,wCAAwC;EACxC,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".access-control {\n  font-family: Arial, sans-serif;\n  background-color: rgba(231, 237, 244, 1);\n  color: #505e71;\n  padding: 20px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.access-control-container {\n  font-family: Arial, sans-serif;\n  border-radius: 10px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: row;\n  overflow-y: auto;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
