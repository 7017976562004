// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quarantine-list-item-parent {
  display: flex;
  flex-direction: column;
}

.review-button-reviewed {
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
}

.review-button-reviewed:hover {
  background-color: #298374;
}

.review-button-unreviewed {
  background-color: #a0475f;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
}

.review-button-unreviewed:hover {
  background-color: #bd5671;
}
.disabled-element {
  opacity: 0.5; 
  pointer-events: none; 
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/AccessControls/Components/QuarantineList/Components/QuarantineListItem/QuarantineListItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".quarantine-list-item-parent {\n  display: flex;\n  flex-direction: column;\n}\n\n.review-button-reviewed {\n  color: white;\n  border: none;\n  padding: 8px 16px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 14px;\n  margin: 4px 2px;\n  cursor: pointer;\n  border-radius: 3px;\n}\n\n.review-button-reviewed:hover {\n  background-color: #298374;\n}\n\n.review-button-unreviewed {\n  background-color: #a0475f;\n  color: white;\n  border: none;\n  padding: 8px 16px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 14px;\n  margin: 4px 2px;\n  cursor: pointer;\n  border-radius: 3px;\n}\n\n.review-button-unreviewed:hover {\n  background-color: #bd5671;\n}\n.disabled-element {\n  opacity: 0.5; \n  pointer-events: none; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
